a {
  text-decoration: none;
}

p {
  line-height: 1.5rem;
}

.outer-wrapper {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inner-wrapper {
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-wrapper {
  min-height: 100vh;
  max-width: 100vw;
}

.desktop-navigation {
  display: flex;
  gap: 2rem;
}

.landing-page-section {
  display: grid;
  grid-template-columns: 50% 45%;
  align-items: center;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("./assets/landing-bg.png");
}
header {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: auto;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  box-shadow: 2px -1px 40px rgba(0, 0, 0, 0.03);
  background-color: #fff;
}
header img {
  height: 1.3rem;
}
.nav-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 1rem;
}

.nav-waitlist-btn {
  background-color: #003049;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  border: 0;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.nav-waitlist-btn:hover {
  background-color: rgba(2, 58, 88, 0.918);
  transition: 0.6s;
  cursor: pointer;
}

.navigation-wrapper > li > a {
  text-decoration: none;
  color: #003049;
}
.navigation-wrapper {
  display: flex;
  text-decoration: none;
  list-style: none;
  gap: 2rem;
  padding: 0;
}

.content-wrapper {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5rem;
}

.verified {
  position: absolute;
  width: 200px;
  height: auto;
  top: 13rem;
  right: -8rem;
}
.landing-image-wrapper > img {
  /* position: relative; */
  max-width: 100%;
}
.join-span {
  color: #068513;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
}

.header-text {
  color: #003049;
  font-size: 2.7em;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.header-text-2 {
  color: #003049;
  font-size: 2.4em;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.small-descr {
  font-size: 14px;
  color: #cf97ce;
  padding: 0;
  margin: 0;
  margin-bottom: 0.4rem;
}
.emphasis {
  color: #660c63;
}
.text-content {
  color: #003049;
  font-size: 22px;
  line-height: 1.8rem;
  font-weight: 400;
  margin-top: 1rem;
  padding: 0;
}

/* .form-wrapper {
  display: flex;
  background-color: #ffffff;
  min-width: 600px;
  width: 600px;
  padding: 0.5rem;
  border-radius: 0.8rem;
} */

/* .form-wrapper:hover {
  border: 1.5px solid #c5c5c5;
  transition: 0.6s;
} */

.email-input {
  width: 100%;
  border: 0;
  background-color: #e0e0e0;
  margin-right: 0.5rem;
  border-radius: 0.8rem;
  padding: 0;
  padding-left: 0.5rem;
  outline: none;
}
.join-waitlist-btn {
  background-color: #003049;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  min-width: 12rem;
  border: 0;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.join-waitlist-btn:hover {
  background-color: #003049;
  font-size: 16.1px;
  transition: 0.6s;
  cursor: pointer;
  gap: 0.8rem;
}
.email-error {
  color: #f14f4f;
}

.img-set {
  margin: 0;
  padding: 0;
  top: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
}

.set2 {
  margin-top: 5rem;
}

footer {
  bottom: 2rem;
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  /* max-width: 100%; */
  padding: 2rem 4rem;
  background-color: #003049;
}

footer p {
  color: #ffffff;
  margin: 0;
  padding: 0;
  font-weight: 600;
}

footer a {
  text-decoration: none;
  color: #e6e6e6;
  font-size: 14px;
}
.contact-us {
  /* display: flex;
  flex-direction: column;
  padding-right: 2rem;
  gap: 1rem; */
}

.contact-us > div > p {
  /* display: flex;
  align-items: center;
  gap: 0.4rem; */
}

.success-msg {
  color: #aaaaaa;
  font-size: 20px;
  font-weight: bold;
}
.copyright {
  /* padding-left: 2rem; */
}
.socials {
  padding-right: 2rem;
}

.socials a {
  width: 300px;
  font-size: large;
  margin-right: 0.5rem;
}

.section-wrapper {
  box-shadow: 2px -1px 40px rgba(0, 0, 0, 0.03);
  border-radius: 17px;
  background: #fafafa;
  padding-top: 2rem;
  padding-bottom: 2rem;
  max-width: 95%;
}

.first-call-to-action {
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.09);
  background: #ffffff;
  border-radius: 40px;
  padding-left: 5rem;
  margin: 0 5rem;
  max-width: 100%;
}

.first-call-to-action img {
  height: 30rem;
  border-radius: 40px;
}

.cta-image {
  display: flex;
  justify-content: flex-end;
}
.first-cta-text {
}

.first-text-section {
  display: grid;
  grid-template-columns: 40% 45%;
  flex-wrap: wrap;
  gap: 10%;
  margin-top: 5rem;
  align-items: center;
  justify-content: center;
}
.happy-user {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("./assets/fs-bg.png");
}

.happy-user img {
  transform: translate(50px, 50px);
  width: 30rem;
  height: 30rem;
}
.value-proposition {
  display: grid;
  grid-template-columns: 40% 40%;
  column-gap: 10%;
  row-gap: 3.5rem;
  align-items: center;
  margin-top: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
}
.buyer-images {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("./assets/fs-bg.png");
  width: 30rem;
  height: 30rem;
}

.buyer-images img {
  transform: translate(50px, 50px);
  width: 30rem;
  height: 30rem;
}

.buyer-values {
  display: flex;
  align-items: start;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.buyer-values img {
}

.team-mgt-card {
  position: absolute;

  left: -5rem;
  bottom: 0.5rem;
}
.best-fit-card {
  position: absolute;
  top: 13rem;
  right: 3rem;
}

.testimonials {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100vw;
}
.stat-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
.stat-card {
  box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.09);
  background: #ffffff;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 30% 70%;
  min-width: 260px;
  align-items: left;
  padding: 0.5rem;
}
.stat-card img {
  width: 50px;
  height: auto;
}

.stat-card p {
  margin: 0;
  padding: 0;
}

.stats {
  font-weight: 600;
  font-size: 1.5rem;
}

.second-call-to-action {
  display: grid;
  grid-template-columns: 40% 52%;
  column-gap: 1rem;
  align-items: center;
  box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.09);
  background: #003049;
  border-radius: 40px;
  padding-left: 5rem;
  margin: 0 5rem;
  position: relative;
  margin-top: 5rem;
  /* max-height: 30rem; */
}

.second-call-to-action img {
  height: 80%;
  max-height: 500px;
}

.second-cta-text-wrapper {
  max-width: 100%;
}
.small-ring {
  position: absolute;
  top: -2.5rem;
  right: -4.5rem;
  width: 150px;
  height: 150px;
}

.transparent-btn {
  /* background-color: transparent; */
  background-color: #8b1d88;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  min-width: 12rem;
  border: 0;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.transparent-btn:hover {
  background-color: #b52cb1;
  font-size: 16.1px;
  transition: 0.6s;
  cursor: pointer;
  gap: 0.8rem;
}

.brand-wrapper {
  display: flex;
}
.brands {
  filter: grayscale(100%);
}
.brands:hover {
  filter: grayscale(0%);
  transition: 0.6s;
}

.faq-section {
  margin-top: 5rem;
  max-width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.testimonials-wrapper {
  display: flex;
  gap: 1rem;
  margin-top: 8rem;
  align-items: center;
  justify-content: center;
}

.testimonial-cards {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
  border-radius: 19px;
  padding: 1.5rem;
  padding-top: 3rem;
  position: relative;
  max-width: 300px;
  min-height: 120px;
}

.testimonial-quotes {
  position: absolute;
  top: -1.5rem;
  left: 3rem;
  opacity: 0.4;
}

.testimonial-avatars {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.testifier-details {
  font-size: 12px;
  color: #888888;
}
.testimonial {
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.faq-tab {
  display: flex;
  margin-top: 2rem;
}

.tab {
  transition: 0.2s;
  padding: 0.8rem 0.5rem;
  /* margin: 0 0.5rem; */
  border: 0px;
  background: none;
  font-size: 1rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  outline: none;
}
.tab:hover {
  cursor: pointer;
  color: #003049;
  border-bottom: 0.2rem solid #b52cb1;
  transition: 0.2s;
}

.active-tab {
  color: #003049;
  border-bottom: 0.2rem solid #b52cb1;
  font-weight: bold;
}

.active-question-card {
  margin-left: 1rem;
  color: #003049;
}
.active-question-card > div > h3 {
  color: #003049;
}
.question-card {
  padding: 0.5rem;
  margin: 1rem 0;
  min-width: 100%;
  border-radius: 0.5rem;
  background-color: #f7f7f7;
}
.question-card:hover {
  transition: 0.8s;
  margin-left: 1rem;
  /* cursor: pointer; */
}

.question-bar {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.question {
  color: #003049;
  font-weight: 600;
}
.answer {
  display: none;
}
.active-answer {
  color: rgba(0, 0, 0, 0.6);
  display: block;
  padding-bottom: 2rem;
  margin-right: 2rem;
  text-align: justify;
  text-justify: inter-word;
}
.faq-wrapper {
  padding-top: 2rem;
  width: 80vw;
}

.modal-wrapper {
  min-width: 100vw;
  min-height: 100vh;
  height: 100vh;
  gap: 2rem;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2000;
  position: fixed;
  overflow-y: scroll;
  top: 0;
}

.cancel-modal-btn {
  background-color: #fff;
  color: #003049;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  border: 0;
  border-radius: 0.5rem;
  /* padding: 1rem 2rem; */
  /* margin-right: 2rem; */
  /* position: fixed; */
}
.cancel-modal-btn:hover {
  /* background-color: rgb(190, 190, 190); */
  color: #69052f;
  transition: 0.6s;
  cursor: pointer;
}

.expand-question:hover {
  cursor: pointer;
  transition: 0.4s;
  width: 2rem;
}

.form-wrapper {
  max-height: 80%;
  padding: 0;
  display: grid;
  grid-template-rows: 10% 90%;
  flex-direction: column;
  border-radius: 1rem;
  overflow: hidden;
  gap: 0.5rem;
  background-color: #ffffff;
}

.form-wrapper-header > img {
  width: 20%;
  height: auto;
}

.form-wrapper-header {
  display: flex;
  justify-content: space-between;
  /* min-width: 100%; */
  background-color: #fff;
  align-items: center;
  padding: 1.5rem;
}
.form-body-wrapper {
  overflow-y: scroll;
}

.AW-Form-1127788680 > div.loader-wrapper:only-child {
  min-width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  display: flex;
}

.AW-Form-1127788680 > div.loader-wrapper {
  display: none;
}

.menu-btn {
  border: none;
  background-color: transparent;
  font-size: 2rem;
}
.menu-btn:hover {
  border: none;
  color: #660c63;
  font-size: 2rem;
  cursor: pointer;
  transition: 0.6s;
  font-size: 2.1rem;
}
/* ---------------------------------------phone dimension---------------------------------- */
@media only screen and (max-width: 938px) {
  .landing-wrapper {
    width: auto;
  }
  .inner-wrapper {
    margin: 2rem;
  }

  .form-wrapper-header {
    min-width: 100%;
    justify-content: flex-end;
    padding: 0.5rem;
  }
  .form-wrapper-header > img {
    display: none;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
  }

  .mobile-navigation {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    min-width: 80%;
    padding: 1rem;
  }

  .menu-btn {
  }

  .landing-page-section {
    display: flex;
    flex-direction: column-reverse;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
  }
  .content-wrapper {
    padding: 1rem;
    min-height: 100%;
    /* align-items: center;
    justify-content: center; */
  }

  .landing-image-wrapper > img {
    display: none;
    /* max-width: 60%; */
  }

  .navigation-wrapper {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .header-text {
    color: #003049;
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
  .header-text-2 {
    color: #003049;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  .join-waitlist-btn {
    max-width: 40%;
  }

  .first-text-section {
    display: flex;
    /* flex-direction: column-reverse; */
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }

  .happy-user {
    margin-bottom: 6rem;
  }
  .happy-user > img {
    width: 80%;
    height: auto;
  }

  .value-proposition {
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    padding: 0;
  }

  .buyer-images {
    width: 90%;
    height: auto;
    margin-bottom: 5rem;
  }
  .buyer-images > img {
    transform: translate(10px, 10px);
    width: 100%;
    height: auto;
  }

  .extra-images {
    display: none;
  }

  .second-call-to-action {
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    padding: 2rem;
    width: 80%;
    border-radius: 2rem;
  }

  .second-call-to-action > img:first-child {
    width: 100%;
    margin-bottom: 2rem;
  }

  .small-ring {
    top: -2.5rem;
    left: 40%;
    width: 100px;
    height: 100px;
  }
  .toggle-icon {
    /* width: 50px; */
  }
  .toggle-icon img {
    width: 40px;
  }

  footer {
    flex-direction: column-reverse;
    gap: 1rem;
    width: auto;
  }

  .copyright {
    /* padding-left: 0; */
    padding-left: 2rem;
  }
  .contact-us {
    padding-left: 2rem;
  }
  .socials {
    padding-left: 2rem;
    padding-right: 0;
  }

  .faq-section {
    margin: 0;
    padding: 2rem;
  }
}

/* ---------------------------------------tablet dimension---------------------------------- */
@media only screen and (max-width: 951px) {
  .buyer-images > img {
    transform: translate(10px, 10px);
    width: 80%;
    height: auto;
  }
  .tab {
    padding: 1rem;
  }
  .tab:nth-child(1) {
    padding-left: 0;
    margin: 0;
  }
  .tab:last-child {
    padding-right: 0;
  }
  .question-section {
    width: 95%;
    padding-right: 1rem;
  }

  .question {
    color: rgba(0, 0, 0, 0.4);
    font-weight: normal;
  }
}

/* media--------------------------------------------------------------------------------------- */
@media only screen and (max-width: 1423px) {
}

@media only screen and (max-width: 800px) {
  .content-wrapper {
    background-image: none;
    background-size: cover;
  }

  .text-content {
    font-size: 16px;
    font-weight: 600;
  }

  .email-input {
    margin-top: 0.5rem;
    width: 100%;
    margin-right: 0;
    text-align: center;
  }
  .join-waitlist-btn {
    width: auto;
    max-width: 100%;
    margin-top: 0.5rem;
  }
}
